import React from 'react';
import { IConfig } from '../types';

interface IMenuContext {
  edit: (r: IConfig) => void;
  enable: (r: IConfig) => void;
  disable: (r: IConfig) => void;
  del: (r: IConfig) => void;
  send: (r: IConfig) => void;
}
export const MenuContext = React.createContext<IMenuContext>({} as IMenuContext);
import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const StyledModal = styled(Modal)`
  .exec-email-modal-footer div {
    display: flex;
    justify-content: space-between;
  }
`;

export const Agreement = styled.p`
  color: #858585;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: lighter;
`;

export const Note = styled.p`
  color: #8B8B8B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: lighter;
`;

export const LogoWrapper = styled.div`
  position:relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoCaption = styled.div`
  position: absolute;
  background: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  line-height: 100px;
  border-radius: 10px;
`;

export const CancelButton = styled(Button)`
  border-width: 1px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  border-color: #686868;
`;

export const SaveButton = styled(Button)`
  border-width: 1px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  border-color: #0046FF;
  color: #0046FF;
`;

export const StyledLabel = styled.span`
  color: #000000;
  font-size: 16px;
`;

export const StyledInfo = styled.span`
  color: #ccc;
`;

export const LogoSelector = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectedImage = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100px;
  height: 100px;
  border: 1px dashed #4A4A4A;
  border-radius: 10px;
`;

export const LogoButtonWrapper = styled.div`
  width: 300px;
  margin: 0 16px;
  position: relative;
`;

export const LogoButtonMessage = styled.div`
  color: #AAAAAA;
  font-size: 14px;;
  font-weight: lighter;
  font-style: italic,
`;

export const AddLogoIcon = styled.div`
  max-width: 70%;
  max-height: 70%;
  display: flex;
  font-size: 36px;
  color: #279df5;
  background: #fff;
  width: 70%;
  height: 70%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LogoButton = styled(Button)`
  display: flex;
  width: 86px;
  height: 34px;
  border: 1px solid #0046FF;
  color: #0046FF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 0;
`;
import React from 'react';
import { Input } from 'antd';
import { IRecipient } from '../types';

interface IProps {
  value?: IRecipient[],
  onChange?: (v: IRecipient[]) => void,
  [key: string]: any;
}

const Recipients: React.FC<IProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const recipients = value ? value.map(v => v.email).join(',') : '';
  const onInputChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const r = evt.target.value.split(',').filter(email => email.length > 0).map(rs => ({email: rs.trim(), sent: false}));
    onChange(r);
  }

  return <Input.TextArea defaultValue={recipients} onChange={onInputChange} {...rest} autoSize/>
}

export default Recipients;
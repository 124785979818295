import styled from 'styled-components';
import { Table } from 'antd';

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActionBtn = styled.span`
  cursor: pointer;
  padding: 4px;
`

export const StyledTable = styled(Table)`
  .disabled-report {
    color: #ddd;
  }
`
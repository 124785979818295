import React from 'react';
import { ConfigProvider } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import {
  ListConfigPage,
} from './pages';

// /app/executive_report is how AppHub is going to reference it.
const baseUrl = window.__POWERED_BY_QIANKUN__ ? '/app/executive_report' : '';

const App = () => (
  <ConfigProvider prefixCls="exec-email">
    <Router>
      <Switch>
        <Route exact path={`${baseUrl}/`} component={ListConfigPage} />
      </Switch>
    </Router>
  </ConfigProvider>
);

export default App;

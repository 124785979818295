export const comparisonWidgets = [
  {
    widgetName: "UniqueDevicesComparison",
    productType: "EI",
    emailType: "WeeklyReport",
    isImageWidget: true,
    imageUrl:
      "...............................................................................................................................................................................................................................................................",
    isImageReady: false,
  },
  {
    widgetName: "PeakViewershipComparison",
    productType: "EI",
    emailType: "WeeklyReport",
    isImageWidget: true,
    imageUrl:
      "...............................................................................................................................................................................................................................................................",
    isImageReady: false,
  },
  {
    widgetName: "MinutesWatchedComparison",
    productType: "EI",
    emailType: "WeeklyReport",
    isImageWidget: true,
    imageUrl:
      "...............................................................................................................................................................................................................................................................",
    isImageReady: false,
  },
  {
    widgetName: "PlaysComparison",
    productType: "EI",
    emailType: "WeeklyReport",
    isImageWidget: true,
    imageUrl:
      "...............................................................................................................................................................................................................................................................",
    isImageReady: false,
  },
];

export const adComparisionWidget = {
  widgetName: "AdImpressionsComparison",
  productType: "AI",
  emailType: "WeeklyReport",
  isImageWidget: true,
  imageUrl:
    "...............................................................................................................................................................................................................................................................",
  isImageReady: false,
};

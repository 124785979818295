/* tslint:disable: no-string-literal */
export function getQoeType(report) {
  let qoeType = "good";
  for (const widget of report.widgets) {
    if (widget && widget.hasOwnProperty("qoeType")) {
      qoeType = widget.qoeType;
      break;
    }
  }
  return qoeType;
}

export function getInitialDimensionArray(report) {
  let dimension = ["GEO_COUNTRY", "m3.dv.n", "OBJECT_NAME"];
  for (const widget of report.widgets) {
    if (widget && widget.hasOwnProperty("dimensionArray")) {
      if (widget["dimensionArray"].length > 0) {
        dimension = widget["dimensionArray"];
        break;
      } else {
        widget["dimensionArray"] = dimension;
      }
    }
  }
  return dimension;
}

export function getInitialFrequency(report) {
  let frequency = "Weekly";
  if (report.frequency) {
    frequency = report.frequency;
    report["emailType"] = `${report.frequency}Report`;
  }
  for (const widget of report.widgets) {
    if (widget && widget.hasOwnProperty("emailType")) {
      widget["emailType"] = `${frequency}Report`;
    }
  }
  return frequency;
}

const excludedDimension = ["CDN"];
const reLocateDimension = ["GEO_COUNTRY", "m3.dv.n"];
export function getInitialDimensionList(dimensions) {
  if (dimensions.length > 0) {
    const sortedDimensions = dimensions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    const staticDimensions = [];
    const filteredDimension = [];
    sortedDimensions.map((dimension) => {
      if(excludedDimension.includes(dimension.field)){
        return;
      }else if (!reLocateDimension.includes(dimension.field)) {
        filteredDimension.push(dimension);
      } else {
        staticDimensions.push({
          note: 'Automatically added',
          ...dimension,
          disabled: true,
        });
      }
    });
    return staticDimensions.concat(filteredDimension);
  }
  return [];
}

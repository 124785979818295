import axios from 'axios';
import { IConfig, IFilter, ITimezone, IDimension, IPostLogo } from './pages/types';

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: process.env.gateway,
  headers: {
    'Content-Type': 'application/json',
  }
});

function successHandler(response) {
  const { status } = response;
  if (status && status >= 200 && status < 300) {
    return response.data;
  }
  const reject = {
    message: response.msg || response.message || 'unknown errors',
  };
  return Promise.reject(reject);
}

function errorHandler(error) {
  const { response } = error;
  return Promise.reject(response);
}

instance.interceptors.response.use(successHandler, errorHandler);


const urls = {
  configs: (customerId: number) => '/customers/{customerId}/email-configs'.replace('{customerId}', `${customerId}`),
  config: (customerId: number, configId: string) => '/customers/{customerId}/email-configs/{emailConfigId}'.replace('{customerId}', `${customerId}`).replace('{emailConfigId}', `${configId}`),
  send: (customerId: number, configId: string, triggerTime: string) => '/customers/{customerId}/email-configs/{emailConfigId}/trigger-time/{triggerTime}'.replace('{customerId}', `${customerId}`).replace('{emailConfigId}', `${configId}`).replace('{triggerTime}', `${triggerTime}`),
  filters: (customerId: number) => '/customers/{customerId}/filters'.replace('{customerId}', `${customerId}`),
  timezone: (customerId: number) =>'/customers/{customerId}/timezone'.replace('{customerId}', `${customerId}`),
  dimensions: (customerId: number) => '/customers/{customerId}/dimensions'.replace('{customerId}', `${customerId}`),
  s3Logo: (customerId: number, logoId: string) => '/customers/{customerId}/s3-post/logos/{logoId}'.replace('{customerId}', `${customerId}`).replace('{logoId}', `${logoId}`),
  logos: (customerId: number) => '/customers/{customerId}/logos'.replace('{customerId}', `${customerId}`),
  hasAdInsights: (customerId: number) => '/customers/{customerId}/hasAdInsights'.replace('{customerId}', `${customerId}`),
}

export const listConfigs = (customerId: number): Promise<IConfig[]> => {
  const url = urls.configs(customerId);
  return instance.get(url);
}

export const createConfig = (customerId: number, config: IConfig) => {
  const url = urls.configs(customerId);
  return instance.post(url, config);
}

export const updateConfig = (customerId: number, configId: string,  config: IConfig) => {
  const url = urls.config(customerId, configId);
  return instance.put(url, config);
}

export const deleteConfig = (customerId: number, configId: string) => {
  const url = urls.config(customerId, configId);
  return instance.delete(url);
}

export const sendConfig = (customerId: number, configId: string, triggerTime: string) => {
  console.log({customerId, configId, triggerTime})
  const url = urls.send(customerId, configId, triggerTime);
  return instance.post(url);
}

export const getTemplate = (customerId: number): Promise<IConfig> => {
  const url = urls.config(customerId, '0');
  return instance.get(url);
}

export const getFilters = (customerId: number): Promise<IFilter[]> => {
  const url = urls.filters(customerId);
  return instance.get(url);
}

export const getTimezone = (customerId: number): Promise<ITimezone> => {
  const url = urls.timezone(customerId);
  return instance.get(url);
}

export const getDimensions = (customerId: number): Promise<IDimension[]> => {
  const url = urls.dimensions(customerId);
  return instance.get(url);
}

export const getS3Logo = (customerId: number, logoId: string): Promise<string> => {
  const url = urls.s3Logo(customerId, logoId);
  return instance.get(url);
}

export const getLogoList = (customerId: number): Promise<string[]> => {
  const url = urls.logos(customerId);
  return instance.get(url);
}

export const hasAdInsights = (customerId: number): Promise<boolean> => {
  const url = urls.hasAdInsights(customerId);
  return instance.get(url);
}

export default instance;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const logger = console;
const render = (props) => {
  const { container } = props;
  ReactDOM.render(<App />, container ? container.querySelector('#app') : document.querySelector('#app'));
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  logger.log('bootstrap');
}

export async function mount(props) {
  render(props);
}

export async function unmount() {
  const $app = document.querySelector('#app');
  if ($app) {
    ReactDOM.unmountComponentAtNode($app);
  }
}

export async function update(props) {
  logger.log('update props', props);
}
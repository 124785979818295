import React, { useCallback, useContext } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { ActionBtn } from './styled';
import { MenuContext } from './context';

const createColumns = () => {
  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'emailTitle',
      sorter: (a, b) => a.emailTitle > b.emailTitle ? 1 : -1,
    },
    {
      title: 'Filter',
      dataIndex: 'filterName',
      sorter: (a, b) => a.filterName > b.filterName ? 1 : -1,
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      sorter: (a, b) => a.frequency > b.frequency ? 1 : -1,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1,
      render: (text) => <span>{new Date(text).toDateString()}</span>,
    },
    {
      title: 'Creator',
      dataIndex: 'createdBy',
      sorter: (a, b) => a.createdBy > b.createdBy ? 1 : -1,
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (item) => (
        <Dropdown overlay={createMenu(item)} trigger={['click']}>
          <ActionBtn>
            <MoreOutlined />
          </ActionBtn>
        </Dropdown>
      )
    },
  ];
  return columns;
};

const createMenu = (report) => {
  const { edit, enable, disable, del, send } = useContext(MenuContext);
  const handleClick = useCallback(({key}) => {
    switch(key) {
      case 'edit': edit(report); break;
      case 'enable': enable(report); break;
      case 'disable': disable(report); break;
      case 'delete': del(report); break;
      case 'send': send(report); break;
    }
  }, [edit, send]);

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="edit">
        Edit
      </Menu.Item>
      <Menu.Item key="send">
          Send Sample
      </Menu.Item>
      {report.enabled && (
        <Menu.Item key="disable">
          Disable
        </Menu.Item>
      )}
      {!report.enabled && (
        <Menu.Item key="enable">
          Enable
        </Menu.Item>
      )}
      <Menu.Item key="delete">
          Delete
      </Menu.Item>
    </Menu>
  );
  return menu;
};



export default createColumns;
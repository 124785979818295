import React, { useCallback, useState, useEffect } from 'react';
import { StyledSelectLogoModal } from './styled';
import { getS3Logo, getLogoList } from '../../../api';
import { Spin, Button, message } from 'antd';

interface IProps {
  title: string;
  visible: boolean;
  logo: string;
  onCancel: () => void;
  onOk: (formData: any) => void;
  confirmLoading?: boolean;
  cid: number;
}

interface Image {
  url: string;
}

const logger = console;

const LogoSelect: React.FC<IProps> = ({
  title,
  visible,
  logo,
  onCancel,
  onOk,
  confirmLoading = false,
  cid,
}) => {
  const [selectImage, setSelectImage] = useState<Image | null>(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllLogos = useCallback(() => {
    setLoading(true);
    Promise.all([getLogoList(cid)])
      .then(([logos]) => {
        const arr = logos.map((e, i) => ({
          index: i,
          url: e,
          checked: false,
        }));
        setImages(arr);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setImages]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getLogoList(cid)])
      .then(([logos]) => {
        const arr = logos.map((e, i) => ({
          index: i,
          url: e,
          checked: logo === e ? true : false,
        }));
        setImages(arr);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setImages]);

  const chk = (e: any, i: number) => {
    const newList = [...images];
    newList.forEach((img, idx) => (img.checked = idx === i));
    setImages(newList);
    setSelectImage(e);
  };

  const renderImages = () => {
    return images.map((e, i) => (
      <div
        key={i.toString()}
        style={{
          width: '81px',
          height: '81px',
          float: 'left',
          marginRight: (i + 1) % 4 === 0 ? '0px' : '11px',
          marginBottom: '9px',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '81px',
            height: '81px',
            border: e.checked ? '1px solid #002BF7' : '1px solid #4A4A4A',
            borderRadius: '6px',
            float: 'left',
          }}
          onClick={() => chk(e, i)} // tslint:disable-line
          key={i}
        >
          <img
            style={{ maxWidth: '45%', maxHeight: '45%' }}
            src={decodeURI(e.url)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '75px',
            height: '14px',
            justifyContent: 'flex-end',
            transform: 'translateY(-20px)',
          }}
        >
          <img
            src={e.checked ? require('../../../assets/images/select.png') : ''}
            style={{ color: '#002BF7' }}
            width="14px"
            height="14px"
          />
        </div>
      </div>
    ));
  };

  const validateFileSize = (file: any) => {
    const MaxSize = 5 * 1024 * 1024; // 5M
    const isAllow = file && file.size && file.size <= MaxSize;
    return isAllow;
  };

  const changeHandler = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    const fileName = file.name.replace(/\s*\%*/g, '');

    if (!validateFileSize(file)) {
      message.info('Make sure file size does no exceed 5 MB');
      return;
    }
    setLoading(true);
    getS3Logo(cid, fileName).then((s) => {
      const config = {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': file.type,
        }),
        body: file,
      };
      return fetch(s, config)
        .then((response) => response.text())
        .then(() => {
          getAllLogos();
          setLoading(false);
        })
        .catch((err) => {
          logger.error(err);
          setLoading(false);
        });
    });
  };

  const onSave = useCallback(() => {
    if (selectImage && selectImage.url) {
      onOk(selectImage.url);
    } else {
      message.info('please select a logo');
    }
  }, [selectImage]);

  return (
    <StyledSelectLogoModal
      visible={visible}
      confirmLoading={confirmLoading}
      closable={false}
      footer={null}
      mask={true}
      maskStyle={{ borderWidth: 1, background: 'rgba(255,255,255,.5)' }}
    >
      <Spin spinning={loading} style={{ height: '457px' }}>
        <div style={{ height: '58px' }}>
          <div
            style={{
              height: '34px',
              color: '#000000',
              fontSize: '19px',
              float: 'left',
              marginTop: '12px',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: '16px',
            }}
          >
            {title}
          </div>
          <div
            style={{
              background: 'white',
              width: '119px',
              height: '34px',
              position: 'relative',
              textAlign: 'center',
              lineHeight: '34px',
              color: '#0046FF',
              border: '1px solid #0046FF',
              fontSize: '16px',
              fontWeight: 600,
              borderRadius: '6px',
              float: 'right',
              marginTop: '12px',
              marginRight: '11px',
            }}
          >
            Upload New
            <input
              type="file"
              accept="image/*"
              style={{
                height: '34px',
                position: 'absolute',
                top: '0',
                bottom: '0',
                right: '0',
                opacity: '0',
                width: '100%',
              }}
              onChange={changeHandler}
            />
          </div>
        </div>
        <div style={{ width: '100%', borderTop: '1px solid #BBBBBB' }} />
        <div
          style={{
            flexWrap: 'wrap',
            width: '358px',
            height: '270px',
            overflow: 'scroll',
            margin: '20px 16px',
            justifyContent: 'space-between',
            padding: '0px',
          }}
        >
          {renderImages()}
        </div>
        <div style={{ width: '100%', borderTop: '1px solid #BBBBBB' }} />
        <div style={{ height: '58px', textAlign: 'right', display: 'block' }}>
          <Button
            key="cancel"
            style={{
              borderColor: '#686868',
              borderWidth: '1px',
              borderRadius: '6px',
              fontSize: '16px',
              fontWeight: 600,
              marginTop: '12px',
              marginRight: '11px',
              height: '34px',
              color: '#686868',
              width: '78px',
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          ,
          <Button
            key="ok"
            style={{
              borderColor: '#0046FF',
              borderWidth: '1px',
              borderRadius: '6px',
              fontSize: '16px',
              fontWeight: 600,
              marginTop: '12px',
              marginRight: '11px',
              height: '34px',
              color: '#0046FF',
              width: '78px',
            }}
            onClick={onSave}
          >
            Select
          </Button>
        </div>
      </Spin>
    </StyledSelectLogoModal>
  );
};

export default LogoSelect;

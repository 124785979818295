import React, { useState, useCallback, useEffect } from "react";
import { Button, message } from "antd";
import Base from "../Base";
import EditModal from "../EditModal";
import { Title, StyledTable } from "./styled";
import createColumns from "./columns";
import { IConfig, IFilter, ITimezone, IDimension } from "../types";
import { MenuContext } from "./context";
import {
  listConfigs,
  getTemplate,
  createConfig,
  updateConfig,
  deleteConfig,
  sendConfig,
  getFilters,
  getTimezone,
  getDimensions,
  hasAdInsights,
} from "../../api";
import moment from "moment-timezone";
import {
  comparisonWidgets,
  adComparisionWidget,
} from "./utils";

const NEW_REPORT = "New Email";
const logger = console;
const getRowClassName = (record) => (record.enabled ? "" : "disabled-report");

const ListConfigPage = () => {
  const cid = 0; // hard code customer id to be 0. Actual customer id will be determined by backend
  const [title, setTitle] = useState(NEW_REPORT);
  const [modalVisible, setModalVisible] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [report, setReport] = useState<IConfig | null>(null);
  const [reports, setReports] = useState<IConfig[]>([]);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<IConfig | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [canCreateReport, setCanCreateReport] = useState(false);
  const [timezone, setTimezone] = useState<ITimezone | null>(null);
  const [dimensions, setDimensions] = useState<IDimension[]>([]);
  const [adInsightsEnabled, setAdInsightsEnabled] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      listConfigs(cid),
      getTemplate(cid),
      getFilters(cid),
      getTimezone(cid),
      getDimensions(cid),
      hasAdInsights(cid),
    ])
      .then(
        ([
          configs,
          tpl,
          allFilters,
          timezoneInfo,
          customerDimensions,
          adInsightsStatus,
        ]) => {
          configs.forEach((c) => {
            c.key = c._id;
            const filter = allFilters.find(({ fid }) => fid === c.filterId);
            c.filterName = filter ? filter.name : null;
          });
          tpl.recipients = [];
          setReports(configs);
          setTemplate(tpl);
          setFilters(allFilters);
          setLoading(false);
          setCanCreateReport(true);
          setTimezone(timezoneInfo);
          setDimensions(customerDimensions);
          setAdInsightsEnabled(adInsightsStatus);
        }
      )
      .catch((err) => {
        logger.error(err);
        setLoading(false);
      });
  }, []);

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const configs = await listConfigs(cid);
      configs.forEach((c) => {
        c.key = c._id;
        const filter = filters.find(({ fid }) => fid === c.filterId);
        c.filterName = filter ? filter.name : null;
      });
      setReports(configs);
    } catch (err) {
      logger.error(err);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setReports, filters.length]);

  const clickNewReport = useCallback(() => {
    setTitle(NEW_REPORT);
    setModalVisible(true);
    setReport(null);
  }, [setTitle, setModalVisible, setReport]);

  const cancelModal = useCallback(() => {
    setModalVisible(false);
    setReport(null);
  }, [setModalVisible, setReport]);

  const editReport = useCallback(
    (r) => {
      setTitle(`Edit ${r.emailTitle}`);
      setModalVisible(true);
      setReport(r);
    },
    [reports, setTitle, setModalVisible, setReport]
  );

  const enableReport = useCallback(
    async (r: IConfig) => {
      r.enabled = true;
      try {
        await updateConfig(cid, r._id, r);
        await refresh();
      } catch (err) {
        logger.error(err);
      }
    },
    [refresh]
  );

  const disableReport = useCallback(
    async (r: IConfig) => {
      r.enabled = false;
      try {
        await updateConfig(cid, r._id, r);
        await refresh();
      } catch (err) {
        logger.error(err);
      }
    },
    [refresh]
  );

  const deleteReport = useCallback(
    async (r: IConfig) => {
      try {
        await deleteConfig(cid, r._id);
        await refresh();
      } catch (err) {
        logger.error(err);
      }
    },
    [refresh]
  );

  const sendReport = useCallback(
    async (r: IConfig) => {
      setLoading(true);
      const offset = timezone.utcOffset;
      const currentDate = moment().utc().utcOffset(offset);
      logger.log("customer_current_date", currentDate.format());

      let triggerTime = "";
      if (r.frequency === "Monthly") {
        triggerTime = currentDate
          .date(5)
          .startOf("day")
          .format();
      } else if (r.frequency === "Daily") {
        triggerTime = currentDate.startOf("day").format();
      } else {
        triggerTime = currentDate.startOf("isoWeek").format();
      }
      logger.log("email_configuration_id", r._id, "trigger_time:", triggerTime);
      try {
        await sendConfig(cid, r._id, triggerTime).then((res) => {
          logger.log("email_id:", res);
        });
      } catch (err) {
        logger.error(err);
      } finally {
        setLoading(false);
      }
      message.info(`Sent "${r.emailTitle}"`);
    },
    [setLoading, timezone]
  );

  const onOk = useCallback(
    async (formData) => {
      setSubmitting(true);
      logger.log("submitting formdata: ", formData);
      try {
        if (formData._id) {
          formData.widgets.forEach((widget) => {
            if (widget.widgetName === "Top5Contents") {
              widget.widgetName = "TopDimensions";
            }
          });
          comparisonWidgets.forEach((widget) => {
            const widgetIndex = formData.widgets.findIndex(
              (formWidget) => formWidget.widgetName == widget.widgetName
            );
            if (widgetIndex === -1) {
              formData.widgets.push(widget);
            }
          });
          if(formData.aiEnabled){
            const adWidgetIndex = formData.widgets.findIndex((formWidget) => formWidget.widgetName === 'AdImpressionsComparison');
            if (adWidgetIndex === -1) {
              formData.widgets.push(adComparisionWidget);
            }
          }
          await updateConfig(cid, formData._id, formData);
          message.success("Report updated");
        } else {
          formData.createdAt = new Date().toISOString();
          await createConfig(cid, formData);
          message.success("New email created");
        }
        setModalVisible(false);
        await refresh();
      } catch (err) {
        logger.error(err);
      } finally {
        setSubmitting(false);
      }
    },
    [setModalVisible, setSubmitting, refresh]
  );

  return (
    <Base>
      <Title>
        <h2>Executive Email v2.0</h2>
        {canCreateReport && <Button onClick={clickNewReport}>New Email</Button>}
      </Title>
      <MenuContext.Provider
        value={{
          edit: editReport,
          enable: enableReport,
          disable: disableReport,
          send: sendReport,
          del: deleteReport,
        }}
      >
        <StyledTable
          columns={createColumns()}
          loading={loading}
          dataSource={reports}
          showSorterTooltip={false}
          rowClassName={getRowClassName}
          pagination={{ pageSize: 20 }}
        />
      </MenuContext.Provider>
      {(report || template) && filters.length > 0 && dimensions.length > 0 && (
        <EditModal
          key={report ? report._id : "new"}
          title={title}
          visible={modalVisible}
          onCancel={cancelModal}
          reportConfig={report ? report : template}
          filters={filters}
          dimensions={dimensions}
          onOk={onOk}
          confirmLoading={submitting}
          cid={cid}
          adInsightsEnabled={adInsightsEnabled}
        />
      )}
    </Base>
  );
};

export default ListConfigPage;

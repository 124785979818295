import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledSelectLogoModal = styled(Modal)`
  .exec-email-modal-content {
    position: relative;
    border:1px solid #4A4A4A;
    background-color: #fff !important;
    background-clip: padding-box;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    margin-right: 10px;
    margin-top: 111px;
    float: right;
    width:392px;
  }

  .exec-email-modal-body {
    padding: 0 !important;
    font-size: 0 !important;
    line-height: 1 !important;
  }
`;
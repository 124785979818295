import React from 'react';
import { Content } from './styled';


const Base = ({
  children
}) => {
  return (
    <Content className="exec-email-content">
      {children}
    </Content>
  );
}

export default Base;

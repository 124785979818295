import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Select, Checkbox, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  StyledModal,
  Agreement,
  CancelButton,
  SaveButton,
  StyledLabel,
  LogoSelector,
  SelectedImage,
  LogoButtonWrapper,
  LogoButtonMessage,
  AddLogoIcon,
  LogoCaption,
  LogoWrapper,
  StyledInfo,
} from "./styled";
import { IConfig, IFilter, IDimension } from "../types";
import Recipients from "./Recipients";
import LogoSelect from "./LogoSelect";
import {
  getQoeType,
  getInitialDimensionArray,
  getInitialFrequency,
  getInitialDimensionList,
} from "./utils";

const TooltipOverlay = (
  <div style={{ color: "#fff", fontSize: "14px", width: "290px" }}>
    Good Experience: Use this set of criteria for comparison with other OTT
    streams based on satisfactory stream performance, such as no fatal errors
    and wait times less than 10 seconds.
    <br />
    <br /> Best Experience: Use this set of criteria for best-of-class
    comparison with other OTT streams based on optimal stream performance, such
    as no fatal errors, wait times less than 8 seconds, and connection related
    rebuffering less than 0.02%.
  </div>
);

const DimensionTooltip = (
  <div style={{ color: "#fff", fontSize: "14px", width: "290px" }}>
    Device Name and Country are automatically added to the Email.
    <br />
    Top Countries section will only be displayed if there is significant traffic
    present in more than one Country.
  </div>
);

/* tslint:disable: no-string-literal */
interface IProps {
  title: string;
  visible: boolean;
  filters: IFilter[];
  onCancel: () => void;
  onOk: (formData: any) => void;
  reportConfig?: IConfig | null;
  confirmLoading?: boolean;
  dimensions: IDimension[];
  cid;
  adInsightsEnabled: boolean;
}

const EditModal: React.FC<IProps> = ({
  title,
  visible,
  onCancel,
  onOk,
  filters,
  reportConfig = null,
  confirmLoading = false,
  dimensions,
  cid,
  adInsightsEnabled = false,
}) => {
  const [form] = Form.useForm();
  const initialData = reportConfig;
  const [formData, setFormData] = useState(initialData);
  const [logoHover, setLogoHover] = useState(false);
  const initialQoe = getQoeType(formData);
  const initialDimensionArray = getInitialDimensionArray(formData);
  const initialFrequency = getInitialFrequency(formData);
  const initialDimensionList = getInitialDimensionList(dimensions);
  if (!formData.filterId) {
    const allTraffic = filters.find(({ name }) => name === "All Traffic");
    formData.filterId = allTraffic ? allTraffic.fid : filters[0].fid;
    formData.filterName = allTraffic ? allTraffic.name : null;
  } else {
    const filter = filters.find(({ fid }) => fid === formData.filterId);
    formData.filterName = filter ? filter.name : null;
  }
  formData["dimensionArray"] = initialDimensionArray;
  formData["qoeType"] = initialQoe;
  formData["frequency"] = initialFrequency;
  const [availableFilters, setAvailableFilters] = useState(filters);
  const [availableDimensions, setAvailableDimensions] =
    useState(initialDimensionList);
  const [selectLogo, setSelectLogo] = useState(initialData.logo);
  const [modalVisible, setModalVisible] = useState(false);

  const onLogoHover = () => {
    setLogoHover(!logoHover);
  };

  const onSave = useCallback(() => {
    form.validateFields().then(() => {
      if (formData.hasOwnProperty("dimension")) {
        delete formData["dimension"];
      }
      formData.logo = selectLogo;
      formData.__v = initialData.__v;
      setFormData(formData);
      onOk(formData);
    });
  }, [initialData, form, formData, setFormData, selectLogo, onOk]);

  const handleSearch = useCallback(
    (value: string) => {
      if (!value) {
        setAvailableFilters(filters);
      } else {
        const v = value.toLocaleLowerCase();
        const filtered = filters.filter(({ name }) =>
          name.toLocaleLowerCase().includes(v)
        );
        setAvailableFilters(filtered);
      }
    },
    [setAvailableFilters, filters]
  );

  const onValuesChange = useCallback(
    (_, values) => {
      setFormData(values);
    },
    [setFormData]
  );

  const onQoeChange = useCallback(
    (qoeType) => {
      formData.widgets.forEach((widget) => {
        if (widget.hasOwnProperty("qoeType")) {
          widget["qoeType"] = qoeType;
        }
      });
      setFormData(formData);
    },
    [setFormData, formData]
  );

  const onFilterChange = useCallback(
    (filterId) => {
      const filter = filters.find(({ fid }) => fid === filterId);
      formData.filterName = filter ? filter.name : null;
      formData.filterId = filterId;
      setFormData(formData);
    },
    [setFormData, formData]
  );

  const onDimensionsChange = useCallback(
    (dimensions) => {
      formData.widgets.forEach((widget) => {
        if (
          widget.hasOwnProperty("dimensionArray") ||
          widget.hasOwnProperty("dimension")
        ) {
          // dimensions array is introduced later
          widget["dimensionArray"] = dimensions;
        }
      });
      setFormData(formData);
    },
    [setFormData, formData]
  );

  const openSelectLogoModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const cancelSelectLogoModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const onSelectLogo = useCallback(
    (logo: any) => {
      setModalVisible(false);
      setSelectLogo(logo);
    },
    [setModalVisible, setSelectLogo]
  );

  const onFrequencyChange = useCallback(
    (frequency) => {
      formData.widgets.forEach((widget) => {
        if (widget.hasOwnProperty("emailType")) {
          // dimensions array is introduced later
          widget["emailType"] = `${frequency}Report`;
        }
      });
      formData["frequency"] = frequency;
      formData["emailType"] = `${frequency}Report`;
      setFormData(formData);
    },
    [setFormData, formData]
  );

  const validatorSelectedLogo = useCallback(() => {
    if (selectLogo.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject("Logo is required");
  }, [selectLogo]);

  const availableFrequency = [
    {
      name: "Daily (Every Morning)",
      id: "Daily",
    },
    {
      name: "Weekly (Every Monday Morning)",
      id: "Weekly",
    },
    {
      name: "Monthly (5th day every Month)",
      id: "Monthly",
    },
  ];

  const Buttons = (
    <>
      <CancelButton key="cancel" onClick={onCancel}>
        Cancel
      </CancelButton>
      <SaveButton key="ok" loading={confirmLoading} onClick={onSave}>
        Save
      </SaveButton>
    </>
  );

  return (
    <StyledModal
      title={title}
      visible={visible}
      closable={false}
      footer={Buttons}
      confirmLoading={confirmLoading}
      width="562px"
      destroyOnClose
      bodyStyle={{ height: "720px", contain: "content", overflow: "scroll" }}
      maskStyle={{ borderWidth: 1, background: "rgba(30,30,30,.5)" }}
    >
      <Form
        name={name}
        form={form}
        layout="vertical"
        initialValues={formData}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="logo"
          rules={[
            {
              required: true,
              message: "Logo is required",
              validator: validatorSelectedLogo,
            },
          ]}
          label={<StyledLabel>Logo</StyledLabel>}
        >
          <LogoSelector>
            <SelectedImage onClick={openSelectLogoModal}>
              {selectLogo.length > 0 ? (
                <LogoWrapper onMouseEnter={onLogoHover} onMouseLeave={onLogoHover}>
                  {logoHover ? <LogoCaption>Modify</LogoCaption> : null}
                  <img
                    src={decodeURI(selectLogo)}
                    style={{
                      maxWidth: "70%",
                      maxHeight: "70%",
                      display: "block",
                    }}
                  />
                </LogoWrapper>
              ) : (
                <AddLogoIcon>+</AddLogoIcon>
              )}
            </SelectedImage>
            <LogoButtonWrapper>
              <LogoButtonMessage>
                You can choose to upload file from your drive. Make sure file
                size does not exceed 5 MB
              </LogoButtonMessage>
            </LogoButtonWrapper>
          </LogoSelector>
        </Form.Item>

        <Form.Item
          name="emailTitle"
          label={<StyledLabel>Report Name</StyledLabel>}
          rules={[
            {
              required: true,
              message: "Enter name as it would appear in the email",
            },
          ]}
        >
          <Input
            maxLength={32}
            style={{
              color: "#808080",
              fontSize: "14px",
              fontWeight: "lighter",
              borderRadius: "5px",
            }}
          />
        </Form.Item>

        <Form.Item
          name="frequency"
          label={<StyledLabel>Frequency</StyledLabel>}
          rules={[{ required: true, message: "Please select a Frequency!" }]}
        >
          <Select
            showArrow={true}
            filterOption={false}
            onChange={onFrequencyChange}
            style={{
              color: "#808080",
              fontSize: "14px",
              fontWeight: "lighter",
              borderRadius: "5px",
            }}
          >
            {availableFrequency.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="filterId"
          label={<StyledLabel>Filter</StyledLabel>}
          rules={[{ required: true, message: "Please select a filter!" }]}
        >
          <Select
            showSearch
            onSearch={handleSearch}
            onChange={onFilterChange}
            showArrow={true}
            filterOption={false}
            style={{
              color: "#808080",
              fontSize: "14px",
              fontWeight: "lighter",
              borderRadius: "5px",
            }}
          >
            {availableFilters.map(({ fid, name }) => (
              <Select.Option key={fid} value={fid}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="dimensionArray"
          label={
            <span>
              <StyledLabel>
                Dimensions <StyledInfo>Select upto 5</StyledInfo>
                &nbsp;
              </StyledLabel>
              <Tooltip
                placement="right"
                overlayStyle={{ display: "flex" }}
                destroyTooltipOnHide={true}
                overlay={DimensionTooltip}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            { required: true, message: "Please select atleast 1 dimension!" },
            {
              validator: (rule, value, callback) => {
                if (value) {
                  if (value.length > 5) {
                    callback("Please select upto 5 dimensions!");
                  } else if (value.length <= 5) {
                    callback();
                  }
                }
                return;
              },
            },
          ]}
        >
          <Select
            showSearch
            mode="multiple"
            showArrow={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.props.children[0]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={onDimensionsChange}
            style={{
              color: "#808080",
              fontSize: "14px",
              fontWeight: "lighter",
              borderRadius: "5px",
            }}
            optionLabelProp="label"
          >
            {availableDimensions.map(({ group_by, name, note, disabled }) => (
              <Select.Option
                key={group_by}
                value={group_by}
                disabled={disabled}
                label={name}
              >
                <div>
                  {name}
                  {note ? (
                    <span className="dimension-note">- ({note})</span>
                  ) : null}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="qoeType"
          label={
            <span>
              <StyledLabel>QoE Overview&nbsp;</StyledLabel>
              <Tooltip
                placement="right"
                overlayStyle={{ display: "flex" }}
                destroyTooltipOnHide={true}
                overlay={TooltipOverlay}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[{ required: true }]}
        >
          <Select
            onChange={onQoeChange}
            style={{
              color: "#808080",
              fontSize: "14px",
              fontWeight: "lighter",
              borderRadius: "5px",
            }}
          >
            <Select.Option key="good" value="good">
              Good Experience
            </Select.Option>
            <Select.Option key="great" value="great">
              Best Experience
            </Select.Option>
          </Select>
        </Form.Item>

        {adInsightsEnabled && (
          <Form.Item
            name="aiEnabled"
            label={<StyledLabel>Other Product KPIs</StyledLabel>}
            valuePropName="checked"
          >
            <Checkbox
              key="aiEnabled"
              style={{
                color: "#808080",
                fontSize: "14px",
                fontWeight: "lighter",
                borderRadius: "5px",
              }}
            >
              Ad Experience
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item
          name="recipients"
          label={<StyledLabel>Email Recipients</StyledLabel>}
          rules={[
            { required: true },
            () => ({
              validator: (_, value) => {
                for (const v of value) {
                  if (
                    v.email &&
                    !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                      v.email
                    )
                  ) {
                    return Promise.reject("Please enter valid email address");
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Recipients
            placeholder="Enter Comma separated email address"
            style={{
              color: "#808080",
              fontSize: "14px",
              fontWeight: "lighter",
              borderRadius: "5px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Agreement>
            This report must be used in conformance with your Subscription
            Agreement & Data Policy with Conviva. Copyright{" "}
            {new Date().getFullYear()} Conviva Inc. Conviva Confidential.
          </Agreement>
        </Form.Item>
      </Form>
      <LogoSelect
        title="Upload Logo"
        visible={modalVisible}
        onCancel={cancelSelectLogoModal}
        logo={selectLogo}
        onOk={onSelectLogo}
        cid={cid}
      />
    </StyledModal>
  );
};

export default EditModal;
